/* exported initMap */

function initMarkerMap(el) {
  const $this = $(el);
  let i = 0;

  let lat = parseFloat($this.data('lat0'));
  let lng = parseFloat($this.data('lng0'));

  let coordinates = { lat, lng };

  const map = new google.maps.Map(el, {
    zoom: 15,
    center: coordinates
  });

  while ($this.data(`lat${i}`)) {
    lat = parseFloat($this.data(`lat${i}`));
    lng = parseFloat($this.data(`lng${i}`));

    coordinates = { lat, lng };

    // const fillColor = $this.data(`fillcolor${i}`) || '#ff3912';

    const marker = new google.maps.Marker({
      position: coordinates,
      map,
      map_icon_label: `<i class="fas fa-${$this.data(`icon${i}`)}"></i>`
    });

    const infowindow = new google.maps.InfoWindow({
      content: $this.data(`label${i}`)
    });

    marker.addListener('click', () => {
      infowindow.open(map, marker);
    });

    i += 1;
  }
}

function initMap() {
  $(document).ready(() => {
    $('.marker-map').each(function () {
      initMarkerMap(this);
    });
    $('.dynamic-marker-map').each(function () {
      initDynamicMap(this);
    });
  });
}

global.initMap = initMap;
global.initMarkerMap = initMarkerMap;
