/* global SignaturePad */

/**
 * Adjust the with and height of a canvas element in function of the container
 * to be full width, and scale its context with the device pixel ratio
 * @return {undefined}
 */
export function resizeCanvas() {
  const canvas = this;
  const { signaturePad } = this;

  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  const containerWidth = $(canvas.parentElement).width();

  // Update with and height of canvas
  canvas.width = containerWidth * ratio;
  canvas.height = (containerWidth / 3) * ratio;

  // Scale context in function of ratio
  canvas.getContext('2d').scale(ratio, ratio);

  // Get data from signature pad
  const data = signaturePad.toData();

  // Clear signature pad otherwise isEmpty() might return incorrect value
  signaturePad.clear();

  // Redraw data
  signaturePad.fromData(data);
}

/**
 * A function to initialize all canvas elements as a signature pad component
 */
export default function initSignaturePad() {
  const signaturePad = new SignaturePad(this);
  this.signaturePad = signaturePad;

  // Resize canvas to be full width
  resizeCanvas.call(this);
}
