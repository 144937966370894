// variables
import { selectors, classes, messages } from './config';


/**
 * A function to append an error sibling element to $element
 * @param {object} $element
 * @param {string} message
 * @param {string} errorClass
 */
function appendError($element, message, errorClass) {
  const $errorElement = $(
    `<small class='form-error ${errorClass}'>
      ${message}
    </small>`
  );

  $element.after($errorElement);
}

/**
 * A function to remove an error sibling to $element
 * @param {object} $element
 * @param {string} errorClass
 */
function removeError($element, errorClass) {
  $element.siblings(`[class*='${errorClass}']`).remove();
}

/**
 * A function to show an especific error message next an input
 * @param {objet} $input
 * @param {string} message
 * @param {string} errorClass
 */
export function showInputError($input, message, errorClass) {
  // Show input error if only the input hasn't have it yet
  const $elementToAddErrorSibling = $input.hasClass(classes.INPUT_WITH_ICON)
    ? $input.parent()
    : $input;
  const inputHasSameError = $elementToAddErrorSibling.siblings(`.${errorClass}`).length > 0;
  if (inputHasSameError) return;

  appendError($elementToAddErrorSibling, message, errorClass);

  // Add error class to input
  $input.addClass(classes.INPUT_ERROR);
}

/**
 * A function to hide an especific error message next an input by its class
 * @param {objet} $input
 * @param {string} errorClass
 */
export function hideInputError($input, errorClass) {
  // Remove error message
  removeError($input.parent(), errorClass);

  // Remove error class from input only if it hasn't any errors
  const $elementToAddErrorSibling = $input.hasClass(classes.INPUT_WITH_ICON)
    ? $input.parent()
    : $input;
  const inputHasErrors = $elementToAddErrorSibling.siblings(selectors.ERROR).length > 0;
  if (!inputHasErrors) {
    $input.removeClass(classes.INPUT_ERROR);
  }
}

/**
 * A function callback for submit buttons click event,
 * it prevent form submit if it has any error
 */
export default function onClickSubmitForm(e) {
  e.preventDefault();

  const $this = $(this);
  const $parents = $this.parents('form');
  const $form = $parents.length > 0 ? $parents : $(e.target.form);
  const formHasInputWithErrors = $form.find(selectors.INPUT_ERROR).length > 0;

  // Submit form if it has no error and remove submit errors
  if (!formHasInputWithErrors) {
    removeError($this, classes.SUBMIT_ERROR);
    $form.submit();
    return;
  }

  // Show form submit errors
  const formSubmitHasErrors = $this.siblings(selectors.SUBMIT_WITH_ERROR).length > 0;
  const shouldShowError = formHasInputWithErrors && !formSubmitHasErrors;

  if (shouldShowError) {
    appendError($this, messages.SUBMIT_ERROR, classes.SUBMIT_ERROR);
  }
}
