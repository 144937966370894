// variables
import { messages, classes } from './config';

// functions
import { showInputError, hideInputError } from './form-errors';

const INVALID_RUT_ERROR_CLASS = `${classes.ERROR_PREFIX}rut`;


/**
 * A function to format a string into chilean rut
 * @param {string} str
 */
export function formatRut(str) {
  // Remove every character that is not alphanumeric
  const cleanStr = str.replace(/(\W)|(_)/g, '');

  if (cleanStr.length < 2) {
    return cleanStr;
  }

  // Add dots each 3 characters
  const bodyWithPoints = cleanStr
    .slice(0, -1)
    .replace(/[A-Za-z]/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const dv = cleanStr
    .slice(-1)
    .toUpperCase();

  return `${bodyWithPoints}-${dv}`;
}

/**
 * A function to validate if a string is a valid chilean rut
 * @param {string} str
 */
export function validateRut(str) {
  // Do not validate str if it is empty string
  if (str.length === 0) return true;

  const cleanStr = str.replace(/(\W)|(_)/g, '');

  // Reject str if cleanStr has only 1 character
  if (cleanStr.length === 1) return false;

  const body = cleanStr.slice(0, -1);
  const dv = cleanStr.slice(-1).toUpperCase();

  // calculate DV
  let sum = 0;
  let mult = 2;

  for (let i = body.length - 1; i >= 0; i -= 1) {
    sum += parseInt(cleanStr[i], 10) * mult;
    mult = (mult + 1) % 8 || 2;
  }

  // check DV
  switch (sum % 11) {
    case 1: return dv === 'K';
    case 0: return dv === '0';
    default: return `${11 - (sum % 11)}` === dv;
  }
}

/**
 * A function callback for input rut change event,
 * it format the value into rut and validate it
 */
export default function onChangeInputRut() {
  const $input = $(this);
  const value = $input.val();

  // Update input value
  const valueRutFormatted = formatRut(value);
  $(this).val(valueRutFormatted);

  // Show or hide input error
  const valueIsValidRut = validateRut(value);

  if (valueIsValidRut) {
    hideInputError($input, INVALID_RUT_ERROR_CLASS);
  } else {
    showInputError($input, messages.RUT_INVALID_ERROR, INVALID_RUT_ERROR_CLASS);
  }
}
