/* global dateFormat */

$('.week-picker').on('dp.change', () => {
  const value = $('.week-picker').val();
  if (value !== '') {
    const firstDate = moment(value, dateFormat).day(1).format(dateFormat);
    const lastDate = moment(value, dateFormat).day(7).format(dateFormat);

    $('.week-picker').val(
      `${$('.week-picker').val()} - Semana ${moment(value).isoWeek()}`
    );

    $('#period_weekly_start').val(firstDate);
    $('#period_weekly_end').val(lastDate);
  } else {
    $('#period_weekly_start').val('');
    $('#period_weekly_end').val('');
  }
});
