// variables
import { selectors } from './config';

// utilities
import App from '../utilities/app';

// functions and callbacks
import { addResetValueButtonToInputs, onClickResetValueButton } from './input-reset-button';
import applyRules from './apply-rules';
import initDropzone from './dropzone';
import initSignaturePad, { resizeCanvas } from './signature-pad';
import initTempusDominus, { initTempusDominusWeek } from './tempus-dominus';
import onChangeInputRut from './rut';
import onClickSubmitForm from './form-errors';
import resizeTextarea from './resize-textarea';


function disableSubmitButtons() {
  const $buttons = $(selectors.SUBMIT).not('.js-do-not-disable-on-submit');

  // disable buttons after submit to prevent disabling submit inputs
  // with values
  setTimeout(() => {
    $buttons.addClass('disabled').prop('disabled', true);
    App.utils.showLoading($buttons);

    setTimeout(() => {
      $buttons.removeClass('disabled').prop('disabled', false);
      App.utils.hideLoading();
    }, 3000);
  }, 10);

  return true;
}

export default function initFormsComponents() {
  // Implement resizable textarea
  $('textarea')
    .each(resizeTextarea)
    .change(resizeTextarea)
    .keydown(resizeTextarea);

  // Get csrf token
  const csrfToken = $(selectors.CSRF_TOKEN).val();

  // Initialize select2
  $(selectors.SELECT2).select2({
    width: '100%'
  });

  // Initialize tempus dominus
  $(selectors.TEMPUS_DOMINUS).each(initTempusDominus);
  $(selectors.TEMPUS_DOMINUS_WEEK).each(initTempusDominusWeek);

  // Initialize signature pad canvas
  $(selectors.CANVAS).each(initSignaturePad);

  // Initialize dropzones and store it in a list
  $(selectors.DROPZONE)
    .toArray()
    .map(el => initDropzone(el, csrfToken));

  // Implement rut handler
  $(selectors.RUT)
    .each(onChangeInputRut) // When DOM id ready
    .keyup(onChangeInputRut) // When user is writing
    .change(onChangeInputRut); // When input changed

  // Add reset buttons to inputs
  addResetValueButtonToInputs();
  $(selectors.RESET_BUTTON).click(onClickResetValueButton);

  // Bind callbacks for components that changes when window is resized
  $(window).resize(() => {
    $(selectors.CANVAS)
      .each(resizeCanvas)
      .resize(resizeCanvas);
  });

  // Implement click submit form handler for errors
  $(selectors.SUBMIT).click(onClickSubmitForm);

  // Implement form submit handler
  $(selectors.FORM).submit(disableSubmitButtons);

  // Apply rules in views with statements tables
  if ($(selectors.STATEMENTS_TABLE).length) {
    applyRules();
  }
}
