// variables
import { selectors } from './config';

function addResetButtonToInput() {
  $(this).after(`
    <button
      aria-label=${'Reset value'}
      class='btn btn-icon btn-link input-reset'
      data-target=${this.id}
      tabindex="-1"
      title=${'Reset value'}
      type='button'
    >
      <span class='icon fas fa-times-circle'></span>
    </button>
  `);
}

/**
 * A function to add reset button to all inputs
 */
export function addResetValueButtonToInputs() {
  // Wrap basic inputs with container for input icons
  $(selectors.INPUT_TO_ADD_ICON)
    .addClass('form-control form-control-icon')
    .wrap('<div class="form-control-icon-container"></div>');

  // Add reset icon after input
  $(selectors.INPUT_WITH_ICON).each(addResetButtonToInput);
}

/**
 * A function callback for reset button click event
 */
export function onClickResetValueButton() {
  const inputTarget = document.getElementById($(this).data('target'));

  // If input target is a canvas, clear it
  if (inputTarget.tagName === 'CANVAS') {
    inputTarget.signaturePad.clear();
    return;
  }

  // Reset input value
  $(inputTarget).val('');
}
