/* global dateFormat */

const tempusDominusFormats = {
  DATE: 'DD/MM/YYYY',
  TIME: 'HH:mm',
  DATETIME: 'DD/MM/YYYY HH:mm',
  MONTH: 'MM/YYYY'
};

const { lang } = document.documentElement;

/**
 * A function to initialize a input as a tempus dominus component
 */
export default function initTempusDominus() {
  const $this = $(this);

  // Set data target
  if (!$this.data('target')) {
    $this.data('target', `#${$this.prop('id')}`);
  }

  // Set format
  let inputFormat = $this.data('format');

  if (!inputFormat) {
    inputFormat = tempusDominusFormats.DATE;
  }

  // Set date
  let inputDate = moment($this.val(), inputFormat);
  if (inputFormat === tempusDominusFormats.TIME) {
    inputDate = moment($this.val(), moment.HTML5_FMT.TIME, inputFormat);
  }

  $this.datetimepicker({
    date: inputDate,
    format: inputFormat,
    locale: lang,
    allowInputToggle: true,
    icons: {
      time: 'fas fa-clock',
      date: 'fas fa-calendar',
      up: 'fas fa-chevron-up',
      down: 'fas fa-chevron-down'
    }
  });
}

/**
 * A function to change the init setup for a tempus dominus component
 * with week type
 */
export function initTempusDominusWeek() {
  const $weekPicker = $(this);

  if ($weekPicker.datetimepicker) {
    $weekPicker.datetimepicker({
      format: dateFormat,
      calendarWeeks: true
    });
  }
}
