import '../scss/main.scss';

// Utilities
import App from './utilities/app';

// Forms
import initFormsComponents from './forms/index';

// Behaviors
import './behaviors/tabs';
import './behaviors/forms';
import './behaviors/gallery';
import './behaviors/maps';
import './behaviors/select-checkbox';

$(() => {
  $('.alert').each(() => {
    App.utils.highlight($(this));
  });

  const $alert = $('.main-alert .alert');
  setTimeout(() => $alert.fadeOut(), 10000);

  $('.datatable-fixed-header').each(function fixDatatableHeader() {
    const t = $(this).DataTable({
      columnDefs: [{
        searchable: false,
        orderable: false,
        targets: 0
      }],
      fixedHeader: true,
      language: {
        url: 'https://cdn.datatables.net/plug-ins/1.10.18/i18n/Spanish.json'
      },
      order: [[1, 'desc']],
      pageLength: 50
    });

    // Add a first column with index cell
    t.on('order.dt search.dt', () => {
      t
        .column(0, {
          search: 'applied',
          order: 'applied'
        })
        .nodes().each((cell, i) => {
          cell.innerHTML = i + 1; /* eslint-disable-line */
        });
    }).draw();
  });

  // Activate tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // Initialize forms components
  initFormsComponents();
});
