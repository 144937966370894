// Scroll constants
const SCROLL_DURATION = 500;
const SCROLL_DISTANCE = 150 * 3; // The width of 3 new tabs
const SCROLL_LEFT = 'left';
const SCROLL_RIGHT = 'right';

const scrollOrientations = {
  [SCROLL_LEFT]: '-',
  [SCROLL_RIGHT]: '+'
};

// Slide constants
const SLIDE_PREV = 'prev';
const SLIDE_NEXT = 'next';

const elementToSlide = {
  [SLIDE_PREV]: $active => $active.prev(),
  [SLIDE_NEXT]: $active => $active.next()
};

// Selectors
const tabsSelector = '.tabs';
const tabSelector = '[data-toggle="tab"]';
const scrollLeftBtnSelector = `[data-scroll=${SCROLL_LEFT}]`;
const scrollRightBtnSelector = `[data-scroll=${SCROLL_RIGHT}]`;
const slideLeftBtnSelector = `[data-slide=${SLIDE_PREV}]`;
const slideRightBtnSelector = `[data-slide=${SLIDE_NEXT}]`;

/**
 * A function to scroll an element horizontally
 * @param {object} $el
 * @param {string} orientation
 */
function scrollElementHorizontally($el, orientation) {
  $el.animate(
    { scrollLeft: `${orientation}=${SCROLL_DISTANCE}` },
    SCROLL_DURATION
  );
}

/**
 * A callback for click event in scroll tabs buttons
 */
function onClickScrollBtn() {
  const orientation = scrollOrientations[$(this).data('scroll')];
  const $target = $(`${$(this).attr('data-target')}`);

  scrollElementHorizontally($target, orientation);
}

/**
 * A callback for click event in slide tabs buttons
 */
function onClickSlideBtn() {
  const $target = $(`${$(this).data('target')}`);
  const $active = $target.find('.active');
  const $targetTab = elementToSlide[$(this).data('slide')]($active);

  // If there is no target tab, do nothing
  if (!$targetTab.length) return;

  // Show tab and scroll to it
  $targetTab.tab('show');
}

/**
 * A callback for scroll event in tabs
 */
function onScrollTabs() {
  const tabs = $(this)[0];
  const tabsId = $(this).attr('id');
  const btnsWithTargetTabSelector = `[data-target="#${tabsId}"]`;

  const $scrollLeftBtnsWithTabTarget = $(btnsWithTargetTabSelector + scrollLeftBtnSelector);
  const $scrollRightBtnsWithTabTarget = $(btnsWithTargetTabSelector + scrollRightBtnSelector);

  const tabsCantScrollToLeft = tabs.scrollLeft === 0;
  const tabsCantScrollToRight = tabs.scrollLeft + tabs.clientWidth === tabs.scrollWidth;

  // Disable left button if tabs can't scroll to left
  if (tabsCantScrollToLeft) {
    $scrollLeftBtnsWithTabTarget.prop('disabled', true);
  } else if ($scrollLeftBtnsWithTabTarget.prop('disabled')) {
    $scrollLeftBtnsWithTabTarget.prop('disabled', false);
  }

  // Disable right button if tabs can't scroll to right
  if (tabsCantScrollToRight) {
    $scrollRightBtnsWithTabTarget.prop('disabled', true);
  } else if ($scrollRightBtnsWithTabTarget.prop('disabled')) {
    $scrollRightBtnsWithTabTarget.prop('disabled', false);
  }

  // Hide both buttons if tabs can't scroll at all
  if (tabsCantScrollToLeft && tabsCantScrollToRight) {
    $scrollLeftBtnsWithTabTarget.prop('hidden', true);
    $scrollRightBtnsWithTabTarget.prop('hidden', true);
  }
}

/**
 * A function to disable or able slide buttons in function of active tab
 * @param {DOMNode} tab
 */
function updateSlideButtonsByActiveTab(tab) {
  const tabsId = $(tab).parent().attr('id');
  const dataTargetTabsIdSelector = `[data-target="#${tabsId}"]`;

  const $slideLeftBtns = $(dataTargetTabsIdSelector + slideLeftBtnSelector);
  const $slideLeftBtnsContainer = $slideLeftBtns.parent();
  const $slideRightBtns = $(dataTargetTabsIdSelector + slideRightBtnSelector);
  const $slideRightBtnsContainer = $slideRightBtns.parent();

  const tabIsFirstEnabledChild = !tab.previousElementSibling || !$(tab).prevAll(':not(.disabled)').length;
  const tabIsLastEnabledChild = !tab.nextElementSibling || !$(tab).nextAll(':not(.disabled)').length;

  // Disable left slide button if first enabled tab is active
  if (tabIsFirstEnabledChild) {
    $slideLeftBtnsContainer.addClass('d-none');
    $slideRightBtnsContainer.addClass('ml-auto');
  } else {
    $slideLeftBtnsContainer.removeClass('d-none');
    $slideRightBtnsContainer.removeClass('ml-auto');
  }

  // Disable right slide button if last enabled tab is active
  if (tabIsLastEnabledChild) {
    $slideRightBtnsContainer.addClass('d-none');
  } else {
    $slideRightBtnsContainer.removeClass('d-none');
  }
}

/**
 * A callback for click event in tab
 */
function onShowTab() {
  const tabOffsetLeft = this.offsetLeft - this.parentElement.offsetLeft;
  $(this).parent().animate(
    { scrollLeft: tabOffsetLeft },
    SCROLL_DURATION
  );

  updateSlideButtonsByActiveTab(this);
}

$(() => {
  // Implement scroll tabs functionality
  $(scrollLeftBtnSelector).click(onClickScrollBtn);
  $(scrollRightBtnSelector).click(onClickScrollBtn);

  // Disable scroll buttons when tabs scroll position is in the start or in the end
  $(tabsSelector)
    .each(onScrollTabs) // Check on document ready
    .scroll(onScrollTabs); // Check on scroll tabs
  if ($(tabsSelector).lenght > 0) {
    $(window).on('resize', () => onScrollTabs.call(tabsSelector)); // Check on window resize
  }

  // Implement slide tabs functionality
  $(slideLeftBtnSelector).click(onClickSlideBtn);
  $(slideRightBtnSelector).click(onClickSlideBtn);

  // Disable slide buttons when active tab first or last child
  $(tabSelector)
    .on('show.bs.tab', onShowTab) // Check on show tab
    .filter('.active')
    .each((index, tab) => updateSlideButtonsByActiveTab(tab)); // Check on document ready
});
