/* global id */

const fields = {};

// Recursive function checking if child fields must be shown or hidden
function applySubsequent(fieldId, hide) {
  const data = fields[fieldId];
  const answerRulesSet = Object.entries(data.answer_rule_set);

  answerRulesSet.forEach(([ruleFieldId]) => {
    const field = fields[ruleFieldId];

    // Permits hiding field if parent is hidden, without explicit rule
    const disabled = hide || Object.values(field.disabled).includes(true);

    const target = $(`.field-input[name$=${ruleFieldId}]`);
    const $field = target.parents('.field');

    if (disabled) {
      $field.hide('slow');
    } else {
      $field.show('slow');
    }

    applySubsequent(ruleFieldId, hide);
  });
}

// Helper function for equality camparators, in case of equal on multiple options
function checkInArray(ruleValue, value) {
  if (Array.isArray(value)) {
    return ruleValue === value[0];
  }

  return false;
}

// Checks diferent rules, returns boolean.
function checkRule(rule, fieldValue) {
  const options = {
    eq: (ruleValue, value) => (
      (ruleValue !== value) && !checkInArray(ruleValue, value)
    ),
    neq: (ruleValue, value) => (
      (ruleValue === value) || checkInArray(ruleValue, value)
    ),
    ct: (ruleValue, value) => !value.includes(ruleValue),
    nct: (ruleValue, value) => value.includes(ruleValue),
    lt: (ruleValue, value) => parseInt(value, 10) >= parseInt(ruleValue, 10),
    gt: (ruleValue, value) => parseInt(value, 10) <= parseInt(ruleValue, 10),
    lte: (ruleValue, value) => parseInt(value, 10) > parseInt(ruleValue, 10),
    gte: (ruleValue, value) => parseInt(value, 10) < parseInt(ruleValue, 10)
  };

  return (options[rule.comparator](rule.value, fieldValue));
}

// Triggered on checkingRule change, apply rule on childs
function iterateRules(data, value) {
  const answerRulesSet = Object.entries(data.answer_rule_set);

  answerRulesSet.forEach(([fieldId, rules]) => {
    const field = fields[fieldId];
    const target = $(`.field-input[name$=${fieldId}]`);
    const $field = target.parents('.field');

    // Check if at least one rule is broken
    const someRulesIsBroken = rules.some(rule => checkRule(rule, value));

    field.disabled[data.id] = someRulesIsBroken;

    // Check if other rules were broken by other checking_fields
    const disabled = Object.values(field.disabled).includes(true);

    // Hide/Show fields
    if (disabled) {
      $field.hide('slow');
      applySubsequent(fieldId, true);
    } else {
      $field.show('slow');
      applySubsequent(fieldId, false);
    }
  });
}

// Group answer_rule_set by field id, returns object rule_set
function groupRules(answerRuleSet) {
  const newRuleSet = {};
  answerRuleSet.forEach((rule) => {
    const { value, comparator } = rule;
    const fieldId = rule.field_id;
    const checkingFieldId = rule.checking_field_id;

    if (!(fieldId in newRuleSet)) {
      newRuleSet[fieldId] = [];
    }

    newRuleSet[fieldId].push({
      checkingFieldId,
      comparator,
      value
    });
  });

  return newRuleSet;
}

// Return object with rule checking id and default false
function createDisable(rulesArray) {
  const data = {};
  rulesArray.forEach((rule) => {
    data[rule.checking_field_id] = false;
  });

  return data;
}

function checkOption(field, values) {
  field.options.forEach((option) => {
    if (option.takes_user_input) {
      const target = $(`.field-option[name$=${option.id}]`);

      if (values.includes(option.value)) {
        target.parent().show('slow');
      } else {
        target.val('');
        target.parent().hide('slow');
      }
    }
  });
}

export default function applyRules() {
  const options = {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    }
  };
  const form = id; // get variable from template

  fetch(`/api/1.0/forms/${form}`, options)
    .then(response => (response.json()))
    .then((data) => {
      data.sections.forEach((section) => {
        section.fieldsets.forEach((fieldset) => {
          fieldset.fields.forEach((field) => {
            const target = $(`.field-input[name$=${field.id}]`);

            // In case rules for answer update/create
            if (target.is('input, textarea, select, .dropzone')) {
              field.value = target.val(); // eslint-disable-line
            }

            // Set up field data
            field.disabled = createDisable(field.rules); // eslint-disable-line
            field.answer_rule_set = groupRules(field.answer_rule_set); // eslint-disable-line
            fields[field.id] = field;

            // Event type in case input type is text or other
            const event = target.is('select, checkbox, radio') ? 'change' : 'input';

            // Attach event to rule check
            target.on(event, function onEventCheckRules() {
              const value = $(this).val();

              iterateRules(field, value);

              if (event === 'change') {
                checkOption(field, value);
              }
            });
          });
        });
      });

      // Initial rules check
      Object.entries(fields).forEach(([id, field]) => {
        const target = $(`.field-input[name$=${id}]`);

        if (target.is('select')) {
          checkOption(field, field.value);
        }

        if (!Object.values(field.disabled).includes(true)) {
          iterateRules(field, field.value);
        }
      });
    });
}
