Dropzone.autoDiscover = false;

const MAX_FILES_TO_UPLOAD = 10;
const ALL_ACCEPTED_MIME_TYPES = null;

const localizedMessages = {
  dictDefaultMessage: gettext('Drop files here to upload'),
  dictFallbackMessage: gettext('Your browser does not support drag\'n\'drop file uploads.'),
  dictFallbackText: gettext('Use the fallback form below to upload your files.'),
  dictFileTooBig: gettext('File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.'),
  dictInvalidFileType: gettext('You can\'t upload files of this type.'),
  dictResponseError: gettext('Server responded with {{statusCode}} code.'),
  dictCancelUpload: gettext('Cancel upload'),
  dictCancelUploadConfirmation: gettext('Are you sure you want to cancel this upload?'),
  dictRemoveFile: gettext('Remove file'),
  dictRemoveFileConfirmation: gettext('Are you sure you want to remove this file?'),
  dictMaxFilesExceeded: gettext('You can not upload any more files.')
};

/**
 * A function to initialize dropzones
 * @param  {DOM element} el
 * @param  {string} csrfToken
 * @return {Dropzone object}
 */
export default function initDropzone(el, csrfToken) {
  const data = $(el).data();
  const { field } = data;
  let {
    maxFiles,
    acceptedFiles
  } = data;

  const paramName = `g_${field}`;
  maxFiles = maxFiles || MAX_FILES_TO_UPLOAD;
  acceptedFiles = acceptedFiles || ALL_ACCEPTED_MIME_TYPES;

  const myDropzone = new Dropzone(el, {
    url: window.location.pathname,
    headers: {
      'X-CSRFToken': csrfToken
    },
    autoProcessQueue: true,
    uploadMultiple: true,
    parallelUploads: 100,
    maxFiles,
    maxFilesize: 10,
    paramName,
    addRemoveLinks: true,
    acceptedFiles,
    ...localizedMessages
  });

  return myDropzone;
}
