$('#select_all').change(function () {
  const status = this.checked;
  $('.checkbox').each(function () {
    this.checked = status;
  });
});

$('#id_requires_task').change(() => {
  if (!$('#id_requires_task').is(':checked')) {
    $('#id_enabled').attr('checked', false);
  }
});
