/**
 * Set behavior to the gallery cms plugin.
 */
$(() => {
  $('.gallery-image-popup')

    // enable popup with gallery for images
    .magnificPopup({
      type: 'image',
      fixedContentPos: true,
      gallery: {
        enabled: true
      },
      image: {
        titleSrc: 'data-text'
      }
    });

  if ($('ul.nav.nav-tabs li a').length) {
    $('ul.nav.nav-tabs li a').click(function () {
      const href = $(this).attr('href');

      $(href).find('.gallery-image').each(function () {
        this.setAttribute('src', this.getAttribute('data-src'));
        this.removeAttribute('data-src');
      });
    });
  } else {
    $('.gallery-image').each(function () {
      this.setAttribute('src', this.getAttribute('data-src'));
      this.removeAttribute('data-src');
    });
  }
});
