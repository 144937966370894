export const classes = {
  ERROR_PREFIX: 'invalid-',
  INPUT_WITH_ICON: 'form-control-icon',
  INPUT_ERROR: 'form-control-invalid',
  SUBMIT_ERROR_MESSAGE: 'prevent-submit'
};

export const messages = {
  SUBMIT_ERROR: gettext('Fix the fields with errors to continue'),
  RUT_INVALID_ERROR: gettext('Invalid RUT')
};

export const selectors = {
  CANVAS: 'canvas',
  CSRF_TOKEN: '[name="csrfmiddlewaretoken"]',
  DROPZONE: '.dropzone',
  ERROR: `[class*=${classes.ERROR_PREFIX}]`,
  FORM: 'form',
  INPUT: '.form-control',
  INPUT_ERROR: `.${classes.INPUT_ERROR}`,
  INPUT_TO_ADD_ICON: '.form-control:not(.input-no-wrap)',
  INPUT_WITH_ICON: `.${classes.INPUT_WITH_ICON}`,
  INPUT_WITHOUT_ICON: '.input-no-wrap',
  RESET_BUTTON: '.input-reset',
  RUT: '.format-rut',
  SELECT2: 'select:not(.js-not-select2)',
  STATEMENTS_TABLE: '.statements-table',
  SUBMIT: '[type="submit"]',
  SUBMIT_ERROR_MESSAGE: `.${classes.SUBMIT_ERROR_MESSAGE}`,
  TEMPUS_DOMINUS: '.datetimepicker-input',
  TEMPUS_DOMINUS_WEEK: '.week-picker'
};
